<template>
    <v-card :href="href" elevation="0" :to="to" class="dashboard-card rounded-xl">
      <img :src="image" alt="" />
      <div class="dashboard-card-body">
        <h3>{{ title }}</h3>
        <p>{{ content }}</p>
      </div>
    </v-card>
</template>

<script>
export default {
  props: {
    href: String,
    to: String,
    image: String,
    title: String,
    content: String,
  },
};
</script>
