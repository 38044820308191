<template>
<v-app>
    <k-app-sidebar />
    <v-main>
        <v-container>
            <div class="pa-3 text-left">
                <h1>Geen toegang</h1>
                <p>Je account heeft niet de juiste rechten voor dit scherm.</p>
                <v-btn :color="'var(--secondary)'" to="/">Naar Dashboard</v-btn>
            </div>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
export default {

};
</script>
