<template>
  <v-dialog
    v-model="dialog_freelance_link"
    class="pa-16"
    width="400"
    content-class="card"
  >
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <k-spacing y="4"></k-spacing>

    <label class="input-label" for="review">Review mail</label>
    <v-switch
      v-model="editsettings.mail"
      inset
      color="var(--primary)"
    ></v-switch>
    <label class="input-label" for="review"
      >Hoeveel procent van deelnemers</label
    >

    <v-row>
      <v-col cols="8">
        <v-select
          v-model="editsettings.amount"
          variant="solo"
          inset
          :items="percentages"
        ></v-select>
      </v-col>
    </v-row>
    <v-checkbox
      v-model="editsettings.double"
      label="Dubbel review toestaan"
    ></v-checkbox>
    <k-spacing y="6"></k-spacing>
    <v-row>
      <v-col cols="8">
        <v-btn
          @click="$emit('update-reviewsettings', this.editsettings)"
          class="btn text-white"
          color="var(--tertiary)"
        >
          Opslaan
        </v-btn>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<style scoped>
.v-input--density-default {
  --v-input-control-height: 0px !important;
  --v-input-padding-top: 16px;
}
</style>
<script>
export default {
  props: {
    settings: Object,
  },
  mounted() {
    this.editsettings =
      this.settings != undefined
        ? this.getOwnPropertyDescriptors(this.settings)
        : {};
    if (this.editsettings.mail) {
      this.editsettings.mail = true;
    }
    if (this.editsettings.double) {
      this.editsettings.double = true;
    }
  },

  data() {
    return {
      percentages: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      dialog_freelance_link: false,
      copysnackbar: "",
      editsettings: {
        mail: false,
        double: false,
        percentages: 100,
      },
      copysnackbar_status: "",
    };
  },
  methods: {
    getOwnPropertyDescriptors(model) {
      var obj = JSON.parse(JSON.stringify(model));

      Object.keys(obj).reduce(function (obj, key) {
        if (obj["_" + key] === obj[key]) {
          delete obj["_" + key];
        }
        return obj;
      }, obj);
      return obj;
    },
  },
};
</script>
