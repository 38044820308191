,
<template>
  <v-dialog v-model="edit_dialog" width="640" content-class="card">
    <v-btn
      icon
      dark
      variant="text"
      class="dialog-close-btn"
      @click="$emit('close-dialog')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <p>Stap {{ step }}/3</p>
    <k-spacing y="10"></k-spacing>
    {{ activity.title }}
    <h2>Activiteit koppelen</h2>
    <k-spacing y="8"></k-spacing>

    <v-window v-model="step">
      <v-window-item value="1">
        <label class="input-label" for="companypage"
          >Bedrijfspagina kiezen</label
        >
        <v-select
          density="compact"
          v-model="editActivity.page"
          variant="solo"
          placeholder="Selecteer een bedrijfspagina"
          item-value="id"
          return-object
          item-title="companyname"
          :items="pages"
        />

        <div v-if="editActivity.page">
          <label class="input-label" for="companypage">Locatie</label>
          <v-radio-group v-model="editActivity.location_type" inline>
            <v-radio label="Op locatie" value="location"></v-radio>
            <v-radio label="Online" value="online"></v-radio>
            <v-radio label="Hybride" value="hybrid"></v-radio>
          </v-radio-group>

          <div v-if="editActivity.location_type != 'online'">
            <label class="input-label" for="location"
              >Vestiging(en) kiezen</label
            >
            <k-spacing y="4"></k-spacing>

            <div
              v-for="(item, index) in editActivity.page.locations"
              :key="index"
            >
              <v-card elevation="0" class="location-card pa-4">
                <div class="d-flex">
                  <h3>{{ item.data.city }}</h3>
                  <v-btn
                    icon
                    dark
                    variant="text"
                    class="ml-auto"
                    @click="editActivity.page.locations.splice(index, 1)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <k-spacing y="4"></k-spacing>

                <label class="input-label" for="location"
                  >Evenementruimte</label
                >

                <v-text-field
                  density="compact"
                  v-model="editActivity.page.locations[index].space"
                  variant="solo"
                  placeholder="Evenementruimte toevoegen"
                />
              </v-card>
              <k-spacing y="4"></k-spacing>
            </div>
          </div>
        </div>

        <k-spacing y="10"></k-spacing>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-btn
              @click="$emit('close-dialog')"
              class="btn btn-tertiary"
              color="var(--tertiary)"
              variant="outlined"
            >
              Annuleren
            </v-btn>
            <v-btn
              @click="nextStep()"
              class="btn text-white ml-auto"
              color="var(--tertiary)"
            >
              Volgende
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="2">
        <v-row>
          <v-col cols="12" md="6">
            <label class="input-label" for="location">Starttijd</label>

            <v-select
              density="compact"
              v-model="editActivity.starttime"
              variant="solo"
              placeholder="Herhaling(en)"
              :items="times"
            />
          </v-col>
          <v-col cols="12" md="6">
            <label class="input-label" for="location">Eindtijd</label>

            <v-select
              density="compact"
              v-model="editActivity.endtime"
              variant="solo"
              placeholder="Herhaling(en)"
              :items="times"
            />
          </v-col>
        </v-row>

        <label class="input-label" for="location">Herhalen</label>
        <v-select
          density="compact"
          v-model="editActivity.recurring_type"
          variant="solo"
          placeholder="Herhaling(en)"
          item-value="type"
          item-title="name"
          :items="recurring_types"
        />
        <v-row>
          <v-col cols="12" md="6">
            <label class="input-label" for="location">Startdatum</label>

            <v-menu
              v-model="startdate_custom_menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  class="input"
                  v-bind="props"
                  v-model="editActivity.startdate"
                  type="text"
                  variant="solo"
                  label="Startdatum"
                  density="compact"
                  single-line
                >
                  <template v-slot:append-inner>
                    <v-icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.3333 1.66797V5.0013M6.66667 1.66797V5.0013M2.5 8.33464H17.5M4.16667 3.33464H15.8333C16.7538 3.33464 17.5 4.08083 17.5 5.0013V16.668C17.5 17.5884 16.7538 18.3346 15.8333 18.3346H4.16667C3.24619 18.3346 2.5 17.5884 2.5 16.668V5.0013C2.5 4.08083 3.24619 3.33464 4.16667 3.33464Z"
                          stroke="#8C8B91"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @click:save="startdate_custom_menu = false"
                @click:cancel="startdate_custom_menu = false"
                title="Selecteer startdatum"
                color="primary"
                v-model="startdate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <label class="input-label" for="location">Einddatum</label>

            <v-menu
              v-model="enddate_custom_menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  class="input"
                  v-bind="props"
                  variant="solo"
                  v-model="editActivity.enddate"
                  type="text"
                  density="compact"
                  label="Einddatum"
                  single-line
                >
                  <template v-slot:append-inner>
                    <v-icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.3333 1.66797V5.0013M6.66667 1.66797V5.0013M2.5 8.33464H17.5M4.16667 3.33464H15.8333C16.7538 3.33464 17.5 4.08083 17.5 5.0013V16.668C17.5 17.5884 16.7538 18.3346 15.8333 18.3346H4.16667C3.24619 18.3346 2.5 17.5884 2.5 16.668V5.0013C2.5 4.08083 3.24619 3.33464 4.16667 3.33464Z"
                          stroke="#8C8B91"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @click:save="enddate_custom_menu = false"
                @click:cancel="enddate_custom_menu = false"
                title="Selecteer einddatum"
                color="primary"
                v-model="enddate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <label class="input-label" for="location"
          >Maximaal aantal deelnemers</label
        >
        <v-text-field
          type="number"
          density="compact"
          v-model="editActivity.spots"
          variant="solo"
          min="1"
          placeholder="Maximaal aantal inschrijvingen"
        />
        <k-spacing y="10"></k-spacing>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-btn
              @click="previousStep()"
             class="btn btn-tertiary"
              color="var(--tertiary)"
              variant="outlined"
            >
              Terug
            </v-btn>
            <v-btn
              @click="nextStep()"
              class="btn text-white ml-auto"
              color="var(--tertiary)"
            >
              Volgende
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="3">
        <label class="input-label" for="location">Freelancers</label>

        <v-autocomplete
          density="compact"
          v-model="editActivity.freelancers"
          variant="solo"
          placeholder="Vul een naam in en druk op Enter of, (komma)"
          name="freelancers"
        ></v-autocomplete>
        <v-row>
          <v-col cols="12" md="6">
            <label class="input-label" for="location">Aantal pauzes</label>
            <v-select
              density="compact"
              v-model="editActivity.breaks"
              variant="solo"
              placeholder="Pauzes"
              :items="breaks"
            />
          </v-col>
          <v-col cols="12" md="6">
            <label class="input-label" for="location">Duur in minuten</label>
            <v-text-field
              type="number"
              max="60"
              min="1"
              density="compact"
              v-model="editActivity.break_duration"
              variant="solo"
              placeholder="Duur in minuten"
            />
          </v-col>
        </v-row>
        <label class="input-label" for="location">Opmerking</label>
        <v-textarea
          density="compact"
          v-model="editActivity.remark"
          variant="solo"
          placeholder="Vul hier je opmerking"
        />
        <k-spacing y="10"></k-spacing>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-btn
              @click="previousStep()"
              class="btn btn-tertiary"
              color="var(--tertiary)"
              variant="outlined"
            >
              Terug
            </v-btn>
            <v-btn
              @click="connectActivity()"
              class="btn text-white ml-auto"
              color="var(--tertiary)"
            >
              Opslaan
            </v-btn>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-dialog>
</template>
<style scoped>
.v-selection-control--density-default {
  --v-selection-control-size: 40px;
  right: 8px !important;
}
.v-selection-control--inline .v-label {
  width: auto;
  right: 40px !important;
  position: relative;
}
</style>
<script>
import api from "../services/api";
import config from "../config";
export default {
  props: {
    activity: Object,
  },
  mounted() {
    this.editActivity.spots = this.activity;
  },
  created() {
    this.getPages();
  },
  data() {
    return {
      startdate: null,
      enddate: null,
      datePickerOpen: false,
      times: [
        "00:00",
        "00:05",
        "00:10",
        "00:15",
        "00:20",
        "00:25",
        "00:30",
        "00:35",
        "00:40",
        "00:45",
        "00:50",
        "00:55",
        "01:00",
        "01:05",
        "01:10",
        "01:15",
        "01:20",
        "01:25",
        "01:30",
        "01:35",
        "01:40",
        "01:45",
        "01:50",
        "01:55",
        "02:00",
        "02:05",
        "02:10",
        "02:15",
        "02:20",
        "02:25",
        "02:30",
        "02:35",
        "02:40",
        "02:45",
        "02:50",
        "02:55",
        "03:00",
        "03:05",
        "03:10",
        "03:15",
        "03:20",
        "03:25",
        "03:30",
        "03:35",
        "03:40",
        "03:45",
        "03:50",
        "03:55",
        "04:00",
        "04:05",
        "04:10",
        "04:15",
        "04:20",
        "04:25",
        "04:30",
        "04:35",
        "04:40",
        "04:45",
        "04:50",
        "04:55",
        "05:00",
        "05:05",
        "05:10",
        "05:15",
        "05:20",
        "05:25",
        "05:30",
        "05:35",
        "05:40",
        "05:45",
        "05:50",
        "05:55",
        "06:00",
        "06:05",
        "06:10",
        "06:15",
        "06:20",
        "06:25",
        "06:30",
        "06:35",
        "06:40",
        "06:45",
        "06:50",
        "06:55",
        "07:00",
        "07:05",
        "07:10",
        "07:15",
        "07:20",
        "07:25",
        "07:30",
        "07:35",
        "07:40",
        "07:45",
        "07:50",
        "07:55",
        "08:00",
        "08:05",
        "08:10",
        "08:15",
        "08:20",
        "08:25",
        "08:30",
        "08:35",
        "08:40",
        "08:45",
        "08:50",
        "08:55",
        "09:00",
        "09:05",
        "09:10",
        "09:15",
        "09:20",
        "09:25",
        "09:30",
        "09:35",
        "09:40",
        "09:45",
        "09:50",
        "09:55",
        "10:00",
        "10:05",
        "10:10",
        "10:15",
        "10:20",
        "10:25",
        "10:30",
        "10:35",
        "10:40",
        "10:45",
        "10:50",
        "10:55",
        "11:00",
        "11:05",
        "11:10",
        "11:15",
        "11:20",
        "11:25",
        "11:30",
        "11:35",
        "11:40",
        "11:45",
        "11:50",
        "11:55",
        "12:00",
        "12:05",
        "12:10",
        "12:15",
        "12:20",
        "12:25",
        "12:30",
        "12:35",
        "12:40",
        "12:45",
        "12:50",
        "12:55",
        "13:00",
        "13:05",
        "13:10",
        "13:15",
        "13:20",
        "13:25",
        "13:30",
        "13:35",
        "13:40",
        "13:45",
        "13:50",
        "13:55",
        "14:00",
        "14:05",
        "14:10",
        "14:15",
        "14:20",
        "14:25",
        "14:30",
        "14:35",
        "14:40",
        "14:45",
        "14:50",
        "14:55",
        "15:00",
        "15:05",
        "15:10",
        "15:15",
        "15:20",
        "15:25",
        "15:30",
        "15:35",
        "15:40",
        "15:45",
        "15:50",
        "15:55",
        "16:00",
        "16:05",
        "16:10",
        "16:15",
        "16:20",
        "16:25",
        "16:30",
        "16:35",
        "16:40",
        "16:45",
        "16:50",
        "16:55",
        "17:00",
        "17:05",
        "17:10",
        "17:15",
        "17:20",
        "17:25",
        "17:30",
        "17:35",
        "17:40",
        "17:45",
        "17:50",
        "17:55",
        "18:00",
        "18:05",
        "18:10",
        "18:15",
        "18:20",
        "18:25",
        "18:30",
        "18:35",
        "18:40",
        "18:45",
        "18:50",
        "18:55",
        "19:00",
        "19:05",
        "19:10",
        "19:15",
        "19:20",
        "19:25",
        "19:30",
        "19:35",
        "19:40",
        "19:45",
        "19:50",
        "19:55",
        "20:00",
        "20:05",
        "20:10",
        "20:15",
        "20:20",
        "20:25",
        "20:30",
        "20:35",
        "20:40",
        "20:45",
        "20:50",
        "20:55",
        "21:00",
        "21:05",
        "21:10",
        "21:15",
        "21:20",
        "21:25",
        "21:30",
        "21:35",
        "21:40",
        "21:45",
        "21:50",
        "21:55",
        "22:00",
        "22:05",
        "22:10",
        "22:15",
        "22:20",
        "22:25",
        "22:30",
        "22:35",
        "22:40",
        "22:45",
        "22:50",
        "22:55",
        "23:00",
        "23:05",
        "23:10",
        "23:15",
        "23:20",
        "23:25",
        "23:30",
        "23:35",
        "23:40",
        "23:45",
        "23:50",
        "23:55",
      ],
      startdate_custom_menu: false,
      enddate_custom_menu: false,
      step: "1",
      pages: [],
      breaks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      recurring_types: [
        {
          type: "onetime",
          name: "Niet herhaald",
        },
        {
          type: "daily",
          name: "Dagelijks",
        },
        {
          type: "weekly",
          name: `Wekelijks `,
        },
        {
          type: "monthly",
          name: `Maandelijks`,
        },
        {
          type: "yearly",
          name: `Jaarlijks `,
        },
        {
          type: "workdays",
          name: `Elke werkdag (maandag t/m vrijdag)`,
        },
        {
          type: "custom",
          name: `Aangepast`,
        },
      ],
      info: {},
      config: config,
      edit_dialog: false,
      copysnackbar: "",
      copysnackbar_status: "",
      editActivity: {
        starttime: "09:00",
        endtime: "12:00",
        location_type: "location",
      },
    };
  },
  watch: {
    startdate(newDate) {
      if (newDate) {
        this.editActivity.startdate = new Date(newDate).toLocaleDateString(
          "nl-NL"
        );
      }
    },
    enddate(newDate) {
      if (newDate) {
        this.editActivity.enddate = new Date(newDate).toLocaleDateString(
          "nl-NL"
        );
      }
    },
  },

  methods: {
    openDatePicker() {
      this.datePickerOpen = true;
    },
    closeDatePicker() {
      this.datePickerOpen = false;
    },

    connectActivity() {
      this.editActivity.activity = this.activity.id;
      this.editActivity.duration = this.activity.duration;

      this.$emit("update-activity", this.editActivity);
      this.$emit("close-dialog");
    },
    nextStep() {
      if (this.step == 1) {
        this.step = "2";
      } else if (this.step == 2) {
        this.step = "3";
      }
    },
    previousStep() {
      if (this.step == 2) {
        this.step = "1";
      } else if (this.step == 3) {
        this.step = "2";
      }
    },
    getPageInfo(item) {
      api
        .get(`${config.url.api}/v1/pages/${item.id}/info`)
        .then((res) => {
          this.info = res.data.success;
          this.ready = true;
        })
        .catch(() => {});
    },
    getPages() {
      api
        .get(`${config.url.api}/v1/pages/0`)
        .then((res) => {
          this.pages = res.data.success;
        })
        .catch(() => {});
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
