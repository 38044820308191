<template>
  <router-view v-if="ready" />
</template>

<script>
const cookies = require("js-cookie");

import api from "./services/api";
import config from "./config/index";
import { useMainStore } from "./stores/store";

export default {
  components: {},
  data: () => ({
    ready: false,
  }),
  beforeMount() {
    if (
      !location.pathname.startsWith("/inloggen") &&
      !location.pathname.startsWith("/link") &&
      !location.pathname.startsWith("/registratie-voltooien") &&
      !location.pathname.startsWith("/wachtwoord-wijzigen") &&
      !location.pathname.startsWith("/wachtwoord-vergeten")
    ) {
      if (cookies.get("fmw_refresh_token")) {
        api
          .get(`/v1/users/me/info`)
          .then((res) => {
            if (res.data.role == "admin") {
              useMainStore().$patch({
                user: res.data,
              });
              this.ready = true;
            } else {
              cookies.remove("fmw_access_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("fmw_refresh_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("user", {
                domain: config.cookiedomain,
                path: "/",
              });
              location.replace(
                `${config.url.login}/inloggen?r=${encodeURI(location.href)}`
              );
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              cookies.remove("fmw_access_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("fmw_refresh_token", {
                domain: config.cookiedomain,
                path: "/",
              });
              cookies.remove("user", {
                domain: config.cookiedomain,
                path: "/",
              });
              location.replace(
                `${config.url.login}/inloggen?r=${encodeURI(location.href)}`
              );
            } else {
              location.replace(
                `${config.url.login}/inloggen?r=${encodeURI(location.href)}`
              );
            }
          });
      } else {
        location.replace(
          `${config.url.login}/inloggen?r=${encodeURI(location.href)}`
        );
      }
    } else {
      this.ready = true;
    }
  },
};
</script>
