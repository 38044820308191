import axios from 'axios';
import config from '../config/index'
axios.defaults.baseURL = `${config.url.api}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const instance = axios.create({
    baseUrl: `${config.url.api}`,
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;