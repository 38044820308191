<template>
  <v-btn v-bind="$attrs"  :color="color" :elevation="elevation" :large="large" :small="small"
    :block="block" :icon="icon" :text="text" :light="light" :dark="dark" :to="to" :href="href">
    <slot></slot>
  </v-btn>
</template>

<script>

import VBtn from '../../plugins/vuetify';
export default {
  name: "VaBtn",
  extends: VBtn,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    elevation: {
      type: Number,
      default: 0,
    },
  },
};
</script>

