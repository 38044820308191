<template>
  
    <v-img v-if="light" :width="width" :height="height" :src="require('@/assets/img/logo-light.svg')"></v-img>
    <v-img v-else  :width="width" :height="height" :src="require('@/assets/img/logo.svg')"></v-img>

</template>


<script>
export default {
  props: {   
    width: String,
    height: String,
    content: String,  
  },

  data(){
    return{
      light: false,
    }
  }
};
</script>