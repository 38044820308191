import "./assets/scss/styles.scss";
import { createApp } from 'vue';
import App from './App.vue';
import setupInterceptors from './services/setupInterceptors';
import { createPinia } from 'pinia';

import router from './router';
import { useMainStore } from './stores/store';
import vuetify from './plugins/vuetify';

import { loadFonts } from './plugins/webfontloader';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

/************************************************
 * Custom project components
 ************************************************/



const app = createApp(App);
import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);

app.component('QuillEditor', QuillEditor);
import FileUploadField from './components/vapps/fileuploadfield';
import ColorPickerMenu from './components/colorpickermenu';
import Dashboardcard from './components/dashboardcard';
app.component('color-picker-menu', ColorPickerMenu);
app.component('dashboard-card', Dashboardcard);

/************************************************
 * Kyano apps components
 *
 * 1. THEME
 * 2. PERMISSIONS
 ************************************************/

/************************************************ 
 * 1. THEME
 ************************************************/

// App default logo
import KAppLogo from './components/framework/theme/k-app-logo';
app.component('k-app-logo', KAppLogo);

// App Sidebar
import KAppSidebar from './components/framework/theme/k-app-sidebar';
app.component('k-app-sidebar', KAppSidebar);

// App Topbar
import KAppTopbar from './components/framework/theme/k-app-topbar';
app.component('k-app-topbar', KAppTopbar);

// App 404
import KApp404 from './components/framework/theme/k-app-404';
app.component('k-app-404', KApp404);

/************************************************ 
 * 2. PERMISSIONS
 ************************************************/

import KNoPermission from './components/framework/permissions/k-no-permission';
app.component('k-no-permission', KNoPermission);



import KSpacing from './components/framework/components/k-spacing';
app.component('k-spacing', KSpacing);


import KAvatarEdit from './components/framework/components/k-avatar-edit';
app.component('k-avatar-edit', KAvatarEdit);


import KFileupload from './components/framework/components/k-fileupload';
app.component('k-fileupload', KFileupload);

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
app.component('VueDatePicker', VueDatePicker);

/************************************************
 * Toast
 ************************************************/
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
app.config.globalProperties.$toast = toast;


/************************************************
 * Companypages components
 ************************************************/



import DConnectClientActivity from './components/connect-client-activity';
app.component('d-client-activity', DConnectClientActivity);

import DFreelanceLink from './components/companypages/dialogs/freelance-link';
app.component('d-freelance-link', DFreelanceLink);
import DPageSettings from './components/companypages/dialogs/page-settings';
app.component('d-page-settings', DPageSettings);
import DReviewSettings from './components/companypages/dialogs/review-settings';
app.component('d-review-settings', DReviewSettings);






/************************************************
 * Vuetify v-apps components
 ************************************************/

import VaCard from './components/vapps/vacard';
import VaBtn from './components/vapps/vabtn';
import VaDialog from './components/vapps/vadialog';
import FlagIcon from 'vue-flag-icon'

// VCard
app.component('va-card', VaCard);
// VBtn
app.component('va-btn', VaBtn);

// VDialog
app.component('va-dialog', VaDialog);

// File upload field
app.component('file-upload-field', FileUploadField);

const pinia = createPinia();

setupInterceptors();
loadFonts();
app.use(FlagIcon);

app.use(router);
app.use(pinia);
app.use(useMainStore());
app.use(vuetify);

app.mount('#app');
