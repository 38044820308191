<template>
  <v-navigation-drawer
    app
    location="left"
    v-model="editdrawer"
    width="316"
    height="100%"
    class="k-app-sidebar"
  >
    <div class="k-app-sidebar-head">
      <k-app-logo
        style="cursor: pointer"
        @click="$router.push('/')"
        width="170"
      ></k-app-logo>
    </div>
    <k-spacing y="6"></k-spacing>
    <div class="k-app-sidebar-body">
      <v-list nav color="var(--primary)">
        <v-list-item
          link
          v-for="item in sidebar_items"
          :key="item.title"
          :to="item.to"
          rounded="xl"
          base-color="var(--neutral-700)"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <div class="k-app-sidebar-footer" v-if="mainStore.user.id != -1">
      <v-list lines="two">
        <v-list-item
          :title="mainStore.user.firstname + ' ' + mainStore.user.lastname"
          :subtitle="mainStore.user.email"
          @click="$router.push('/accountgegevens')"
        >
          <template v-slot:prepend>
            <v-avatar
              style="cursor: pointer"
              @click="$router.push('/accountgegevens')"
              color="var(--primary-300)"
              size="48"
              :image="`${configdata.url.cdn}/users/avatars/${mainStore.user.profilepicture}`"
            ></v-avatar>
          </template>

          <template v-slot:append>
            <v-btn
              @click.prevent="signout()"
              color="#696969"
              icon="mdi-logout"
              variant="text"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useMainStore } from "@/stores/store";
import config from "@/config";
const cookies = require("js-cookie");
import api from "../../../services/api";

export default {
  setup() {
    // const mainStore = useMainStore();
    // return {
    //   name: mainStore.user.firstname + ' ' + mainStore.user.lastname,
    //   profilepicture: mainStore.user.profilepicture,
    //   userMail: mainStore.user.email,
    // }
  },
  data: () => ({
    mainStore: useMainStore(),
    editdrawer: false,
    admin_items: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard-outline",
        to: "/",
      },
      {
        title: "Activiteiten",
        icon: "mdi-meditation",
        to: "/activiteiten",
      },
      {
        title: "Bedrijfspagina's",
        icon: "mdi-briefcase-outline",
        to: "/bedrijfspaginas",
      },
      {
        title: "Accountgegevens",
        icon: "mdi-account-circle-outline",
        to: "/accountgegevens",
      },
      {
        title: "Reviews",
        icon: "mdi-star-outline",
        to: "/reviews",
      },
      {
        title: "Instellingen",
        icon: "mdi-cog-outline",
        to: "/instellingen",
      },
      {
        title: "Specialisten",
        icon: "mdi-account-box-outline",
        to: "/specialisten",
      },

    ],

    mini: true,
  }),
  watch: {
    drawer() {
      this.editdrawer = this.drawer;
    },
    editdrawer() {
      this.$emit("open-drawer", this.editdrawer);
    },
  },
  props: {
    drawer: Boolean,
  },
  computed: {
    configdata() {
      return config;
    },
    sidebar_items() {
      if (this.mainStore.user.id == -1) {
        return [];
      } else {
        return this.admin_items;
      }
    },
  },
  mounted() {
    this.editdrawer = this.drawer;
  },
  methods: {
    redirect(item, type) {
      if (type == "link") {
        window.open(item, "_blank");
      } else {
        this.$router.push(item);
      }
    },

    signout() {
      api
        .put(`v1/auth/logout`, {
          refresh_token: cookies.get("fmw_refresh_token"),
        })
        .then((res) => {
          if (res.data.success) {
            cookies.remove("fmw_access_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("fmw_refresh_token", {
              domain: config.cookiedomain,
              path: "/",
            });
            cookies.remove("user", {
              domain: config.cookiedomain,
              path: "/",
            });
            location.replace(config.url.login);
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            default:
              this.$toast.error("Oops, something went wrong");
              break;
          }
        });
    },
  },
};
</script>
